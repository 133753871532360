import React from 'react';
import { graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Layout from '../components/Layout';
import Banner from '../components/Banner';
import Breadcrumbs from '../components/Breadcrumbs';
import EquipmentCardListing from '../components/EquipmentCardListing';
import ModularBlocks from '../components/ModularBlocks';

const EquipmentCategoryTemplate = ({
  data: {
    datoCmsEquipmentCategory: {
      seoMetaTags,
      title,
      bannerText,
      bannerImage,
      modularBlocks,
    },
    allDatoCmsEquipment: { nodes },
  },
}) => (
  <Layout>
    <HelmetDatoCms seo={seoMetaTags} />
    <main>
      <Banner heading={title} text={bannerText} image={bannerImage} />
      <Breadcrumbs
        breadcrumb={{ slug: 'equipment', text: 'Equipment' }}
        currentPageTitle={title}
      />
      <ModularBlocks items={modularBlocks} />
      <EquipmentCardListing items={nodes} />
    </main>
  </Layout>
);

export const EquipmentCategoryTemplateQuery = graphql`
  query EquipmentCategoryTemplateQuery($id: String!) {
    datoCmsEquipmentCategory(id: { eq: $id }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      bannerText
      bannerImage {
        ...SubpageBannerImageFragment
      }
      modularBlocks {
        ...ContentModularBlockFragment
        ...ImageContentModularBlockFragment
        ...VideoContentModularBlockFragment
      }
    }
    allDatoCmsEquipment(
      filter: { category: { elemMatch: { id: { eq: $id } } } }
      sort: { fields: position }
    ) {
      nodes {
        ...EquipmentCardFragment
      }
    }
  }
`;

export default EquipmentCategoryTemplate;
