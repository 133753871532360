import React from 'react';
import styled from 'styled-components';
import { minBreakpointQuery, sectionMargins } from '../styles';
import { Container } from './ui';
import EquipmentCard from './EquipmentCard';

const StyledEquipmentCardListing = styled.section`
  ${sectionMargins()};
`;

const StyledInner = styled.div`
  display: grid;
  gap: 30px;

  ${minBreakpointQuery.small`
    grid-template-columns: repeat(2, 1fr);
  `}

  ${minBreakpointQuery.medium`
    gap: 40px;
  `}

  ${minBreakpointQuery.large`
    gap: 50px;
  `}
`;

const EquipmentCardListing = ({ items }) =>
  items.length > 0 && (
    <StyledEquipmentCardListing>
      <Container>
        <StyledInner>
          {items.map(({ id, title, slug, price, images }) => (
            <EquipmentCard
              key={id}
              title={title}
              slug={slug}
              price={price}
              image={images[0]}
            />
          ))}
        </StyledInner>
      </Container>
    </StyledEquipmentCardListing>
  );

export default EquipmentCardListing;
